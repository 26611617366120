import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const createGuest = async (balance) => {
    try {
      const response = await fetch(`${BASE_API_URL}guest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("session")}`,
        },
        body: JSON.stringify({
          balance
        }),
      });
  
      if (!response.ok) {
        throw response;
      }
  
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      httpExpection(e);
      throw await e.json();
    }
  };

  export const deleteGuest = async (user) => {
    try {
      const response = await fetch(`${BASE_API_URL}admin/users/${user}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("session")}`,
        },
      });
  
      if (!response.ok) {
        throw response;
      }
  
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      httpExpection(e);
      throw await e.json();
    }
  };

  export const getGuests = async () => {
    try {
      const response = await fetch(`${BASE_API_URL}guest`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("session")}`,
        },
      });
  
      if (!response.ok) {
        throw response;
      }
  
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
      httpExpection(e);
      throw await e.json();
    }
  };