// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getAllInvoices, cancelInvoice } from "services/invoice-service";

import { Button } from "@mui/material";
import { getSettings, setSettings } from "services/auth-service";
import InputMask from 'react-input-mask';

function Settings() {
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setRecharges] = useState([]);
  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState(null);

  const [intervalAmount, setIntervalAmount] = useState(0);
  const [maxRecharge, setMaxRecharge] = useState(0);

  const getData = async () => {
    try {
      const response = await getSettings();

      setIntervalAmount(Number(response.data.balanceInterval))
      setMaxRecharge(Number(response.data.maxRecharge));

    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  const saveDate = async () => {
    try {
      await setSettings(intervalAmount.toString().replace(/ /g, ''), maxRecharge.toString().replace(/ /g, ''));


      openSuccessSB()
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Actualizado"
      content="Se ha actualizado la configuración correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error al realizar la recarga"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Configuración de app</MDTypography>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Card style={{ marginTop: '24px' }}>
          <Grid container spacing={6} py={2}>
            <Grid item xs={12} md={4}>
              <h5 style={{ margin: '0 24px' }}>Configuración de recargas</h5>
              <FormControl
                fullWidth
                style={{ margin: "8px 24px 8px 24px" }}
                onChange={(e) => setIntervalAmount(e.target.value)}
              >
                <InputMask
                  mask="999999"
                  value={intervalAmount}
                  disabled={false}
                  maskChar=" "
                >
                  {() => <TextField value={intervalAmount} id="outlined-basic" label="Intervalo de recarga" variant="outlined" style={{ marginTop: '8px' }} />}
                  
                </InputMask>

              </FormControl>
              <FormControl
                fullWidth
                style={{ margin: "8px 24px 8px 24px" }}
                onChange={(e) => setMaxRecharge(e.target.value)}
              >
                <InputMask
                  mask="999999"
                  value={maxRecharge}
                  disabled={false}
                  maskChar=" "
                >
                  {() => <TextField value={maxRecharge} id="outlined-basic" label="Máximo de recarga" variant="outlined" style={{ marginTop: '8px' }} />}
                  
                </InputMask>

              </FormControl>
              <MDButton
                style={{ margin: '24px 24px 0 24px' }}
                variant="gradient"
                onClick={() => { saveDate() }}
                color="primary"
                fullWidth
              >
                Guardar
              </MDButton>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
      {renderErrorSB}
      {renderSuccessSB}
    </DashboardLayout>
  );
}

export default Settings;
