import { BASE_API_URL } from "config/config";
import httpExpection from "utils/httpException";

export const login = async (email, password) => {
  try {
    const response = await fetch(`${BASE_API_URL}login/admin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const getSettings = async () => {
  try {
    const response = await fetch(`${BASE_API_URL}settings/app`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};

export const setSettings = async (interval, max) => {
  try {
    const response = await fetch(`${BASE_API_URL}settings/app`, {
      method: "POST",
      body: JSON.stringify({
        balanceInterval: interval,
        maxRecharge: max
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("session")}`,
      },
    });

    if (!response.ok) {
      throw response;
    }

    const data = await response.json();
    return data;
  } catch (e) {
    httpExpection(e);
    throw await e.json();
  }
};