// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormControl from "@mui/material/FormControl";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { getAllEvents } from "services/event-service";
import { getAllRecharges } from "services/recharge-service";

import TablePagination from '@mui/material/TablePagination';

function Recharges() {
  const [activeEvent, setActiveEvent] = useState(0);
  const [events, setRecharges] = useState([]);
  const [message, setMessage] = useState("");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const [pagination, setPagination] = useState(null);

  const columns = [
    { Header: "Monto", accessor: "amount", align: "left" },
    { Header: "Usuario", accessor: "user", align: "left" },
    { Header: "Tarjeta", accessor: "card", align: "left" },
    { Header: "No. Transacción", accessor: "transaction", align: "left" },
    { Header: "Mensaje de transacción", accessor: "transactionMessage", align: "left" },
    { Header: "Realizada", accessor: "success", align: "left" },
    { Header: "Fecha", accessor: "date", align: "left" },
  ];

  const [rows, setRows] = useState([]);

  const getData = async (page = 1) => {
    try {
      const response = await getAllRecharges(page);
      setPagination(response.pagination);
      setRecharges(response.data);
      const currentRows = [];
      response.data.forEach((event) => {
        currentRows.push({
          card: <p>{event.card}</p>,
          amount: <p>Q.{parseFloat(event.amount).toFixed(2)}</p>,
          user: <p>{event.userEmail}</p>,
          transaction: <p>{event.transaction}</p>,
          success:
            event.success === true ? (
              <MDTypography variant="p" color="success">
                Realizada
              </MDTypography>
            ) : (
              <MDTypography variant="p" color="error">
                No Realizada
              </MDTypography>
            ),
          trassactionMessage: <p>{event.trasactionMessage}</p>,
          date: <p>{new Date(event.createdAt).toLocaleDateString("es-GT")}</p>,
        });
      });
      currentRows.reverse();
      setRows(currentRows);
    } catch (e) {
      if (e.message !== null) {
        setMessage(e.message);
      } else {
        setMessage("Error en el servidor");
      }
      openErrorSB();
    }
  }

  useEffect(async () => {
    getData();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Recarga realizada"
      content="La recarga se realizo correctamente"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Ha ocurrido un error"
      content={message}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);


  const handleChange = (event) => {
    setActiveEvent(event.target.value);
  };

  const handleChangePage = (
    event,
    newPage,
  ) => {
    setPage(newPage);
    getData(newPage+1);
  };

  const handleChangeRowsPerPage = (
    event,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item xs={12} md={6} display="flex" alignItems="center">
          <MDTypography variant="h3">Recargas</MDTypography>
        </Grid>
      </Grid>
      <MDBox pb={3}>
        <Grid container spacing={6} mt={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  entriesPerPage={false}
                  table={{ columns, rows }}
                  isSorted={false}
                  showTotalEntries={false}
                  noEndBorder
                />
                {pagination && <TablePagination
                  component="div"
                  count={pagination.totalPages}
                  page={pagination.currentPage-1}
                  onPageChange={handleChangePage}
                  rowsPerPage={pagination.perPage}
                  rowsPerPageOptions={false}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      {renderErrorSB}
    </DashboardLayout>
  );
}

export default Recharges;
